import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    width: "300px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 8px rgb(0 0 0 / 40%)",
    borderRadius: "10px",
    zIndex: 1000,
    // borderTop : '2px solid red',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(2),
  },
  OderListContainer: {
    marginTop: theme.spacing(1.6),
  },
  OderListItemContainer: {
    marginTop: theme.spacing(1.6),
    display: "flex",
    alignItems: 'center'
  },
  text: {
    fontWeight: "bold",
    color: theme.palette.common.fontTitle
  },
  countText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    color: theme.palette.common.fontTitle

  },
  totalContainer: {
    display: "flex",
    borderTop: "2px solid",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
  },
  btn: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
