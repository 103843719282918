import React from "react";

// Containers
export const Home = React.lazy(() => import("../containers/Home/Home"));
export const Table = React.lazy(() => import("../containers/Table/Table"));
// export const Profile = React.lazy(() =>
//   import("../containers/Profile/Profile")
// );
export const Profile = React.lazy(() =>
  import("../containers/ProfileLatest/ProfileLatest")
);
export const Cart = React.lazy(() => import("../containers/Cart/Cart"));
export const Checkout = React.lazy(() =>
  import("../containers/Checkout/Checkout")
);

export const PaymentCallBack = React.lazy(() =>
  import("../containers/PaymentCallBack/PaymentCallBack")
);
export const Orders = React.lazy(() => import("../containers/Orders/Orders"));

// Takey containers
export const TakeyHome = React.lazy(() =>
  import("../containers/Takey/Home/Home")
);
export const TakeyRestaurants = React.lazy(() =>
  import("../containers/Takey/Restaurants/Restaurants")
);
export const TakeyRestaurantCuisines = React.lazy(() =>
  import("../containers/Takey/Cuisines/Cuisines")
);
// home
export const RootHome = React.lazy(() =>
  import("../containers/RootHome/Home/Home")
);
export const HomeRestaurants = React.lazy(() =>
  import("../containers/RootHome/HomeRestaurants/HomeRestaurants")
);

export const Account = React.lazy(() =>
  import("../containers/Account/Account")
);
