import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: "transparent",
    padding: theme.spacing(2.4, 1.6),
    marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4),
      borderRadius: "12px",
      backgroundColor: theme.palette.common.white,
    },
  },
  headerTitle: {
    ...theme.typography.rockwell,
    fontWeight: "bold",
    color: theme.palette.common.normalTitle,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "19px",
    marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1.6),
  },
  itemText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "21px",
    color: theme.palette.common.normalTitle,

  },
  greenText: {
    color: theme.palette.common.green,
  },
  itemPrice: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "21px",
    color: theme.palette.common.normalTitle,

  },
  itemTotalPrice: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "21px",
    color: theme.palette.primary.main
  },
  total: {
    paddingTop: theme.spacing(1.9),
  },
  totalPrice: {
    fontSize: theme.typography.pxToRem(18),
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    width: "100%",
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey,
  },
  mobileContainer: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width: "100%",
    },
  },
  orderTypeModalContainer: {
    width: "450px",
    height: "auto",
  },
}));

export default useStyles;
