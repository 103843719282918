import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
  },
  img: {
    userSelect: "none",
    height: "100%",
    width: "100%",
    borderTopLeftRadius: "inherit",
    borderBottomLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    borderBottomRightRadius: "inherit",
    "&.no-image": {
      opacity: 0.3,
    },
  },
  overlayText: {
    position: "absolute",
    top: 8.5,
    left: 8,
    padding: theme.spacing(0.2, 0.8),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.white,
    borderRadius: 8,
  },
  disabledOverLay: {
    filter: "grayscale(100%)",
    // backgroundColor : theme.palette.common.black,
    // opacity : 0.5,
    // fil
  },
}));

export default useStyles;
