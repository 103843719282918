import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "12px",
    backgroundColor: theme.palette.common.background,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    height: "100%",
    maxWidth: "500px",
    // marginTop: "120px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "480px",
      maxWidth: "830px",
      width: "830px",
    },
  },
  specialPaper: {
    borderRadius: "12px",
    backgroundColor: theme.palette.common.background,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    height: "100%",
    maxWidth: "500px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "250px",
      maxWidth: "830px",
      width: "830px",
    },
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1.5),
    top: theme.spacing(2.4),
    fontWeight: 700,
  },
  modalTitleRoot: {
    padding: theme.spacing(3.2),
    paddingBottom: theme.spacing(1),
  },
  title: {
    ...theme.typography.rockwell,
    fontWeight: 700,
    color: theme.palette.common.normalTitle,
  },
  content: {
    padding: theme.spacing(0, 3.2),
  },
  bgBlack: {
    backgroundColor: "rgb(0, 0, 0)",
  },
}));

export default useStyles;
