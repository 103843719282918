import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // root: {
  //     paddingBottom: theme.spacing(2.4)
  // },
  inputContainer: {
    // marginBottom: theme.spacing(0.8),
    backgroundColor: theme.palette.common.lighterBg,
    // color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  input: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "21px",
    color: theme.palette.common.fontTitle,
    "&::placeholder": {
      color: theme.palette.common.fontTitle, // Change this to the desired color
    },
  },
  notchedOutline: {
    border: "none",
  },
  helperText: {
    marginTop: theme.spacing(0.8),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "18px",
  },
  focused: {
    // border: `1px solid ${theme.palette.primary.main}`
  },
  success: {
    border: `1px solid ${theme.palette.common.green}`,
  },
  error: {
    border: `1px solid ${theme.palette.common.red}`,
  },
  successHelperText: {
    color: theme.palette.common.green,
  },
  errorHelperText: {
    color: theme.palette.common.red,
  },
}));

export default useStyles;
