import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  // Define your styles here
  color: theme.palette.common.fontTitle,
}));

const Btn = ({count, text, handleClick, startIcon, endIcon, disabled, ...props }) => {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={disabled}
      startIcon={startIcon || null}
      endIcon={endIcon || null}
      {...props}
    >
   {  count !== 0 && count !== undefined ? `${text} (${count})` : text}
    </Button>
  );
};

export default Btn;
