import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2.4),
    position: 'relative'
  },
  iconSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "2.5em !important",
    height: "2.5em !important",
    // width: "40px !important",
    // height: "40px !important",
    color: theme.palette.common.red,
  },
  detailSection: {
    marginTop: theme.spacing(2.4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    // fontFamily: Rockwell;
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
  },
  desc: {
    // font-family: Poppins;
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    marginTop: theme.spacing(0.8),
  },
  errorBgColor: {
    backgroundColor: "rgb(0, 0, 0,0.8)",
  },
  errorModalContainer: {
    width: "350px",
    height: "auto",
  },
  actionContainer: {
    marginBottom: theme.spacing(2.4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  link: {
    color: `${theme.palette.common.green} !important`,
    fontSize: theme.typography.pxToRem(12.8),
  },
  closeButton: {
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    width: 32,
    height: 32,
    borderRadius: "50%",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
}));

export default useStyles;
