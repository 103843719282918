import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const getCustomerOrders = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );

  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const getCustomerOrderByPageNo = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}?page=${payload?.payload?.pageNo}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};
