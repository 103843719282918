import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// Redux actions
import {
  showSnackbarAction,
  addGroupRefIdWithMsg,
} from "./../store/Snackbar/SnackbarAction";
import { saveCheckoutForm } from "./../store/Cart/CartActions";
import { order } from "./../store/Order/OrderActions";
// Custom Hooks
import useCalculateDistance from "./useCalculateDistance";
// ValidationSchemas
import SelectOrderTypeSchema from "./../components/Checkout/SelectOrderType/SelectOrderType.schema";
import ContactDetailsFormSchema from "./../components/Checkout/ContactDetailsForm/ContactDetailsForm.schema";
import AddressFormSchema from "./../components/Checkout/AddressForm/AddressForm.schema";
import SelectPaymentMethodSchema from "./../components/Checkout/SelectPaymentMethod/SelectPaymentMethod.schema";
import DeliveryTimeSchema from "./../components/Checkout/DeliveryTime/DeliveryTime.schema";
// Constants
import { ORDER_TYPES, PAYMENT_METHODS, MESSAGES } from "../utils/Constants";
import moment from "moment";

const useCheckoutForm = () => {
  const formValues = useSelector((state) => state.cart.checkoutDetails);
  const { tableId } = useSelector((state) => state.hotel);
  const location = useSelector((state) => state.location);

  const dispatch = useDispatch();
  const [inValid, setInValid] = useState(false);
  const [formGroupsValidation, setFormValidationGroups] = useState({
    orderTypeForm: false,
    deliveryPickupTypeForm: false,
    contactForm: false,
    addressForm: false,
    paymentMethodForm: false,
  });
  const { inValidDistance } = useCalculateDistance();

  const setFormValues = (values) => dispatch(saveCheckoutForm(values));

  const orderButtonText = useMemo(() => {
    if (formValues.paymentMethod === PAYMENT_METHODS.ONLINE_PAYMENT) {
      return `Pay`;
    }
    return `Place order`;
  }, [formValues.paymentMethod]);

  const isDeliveryEnable = useMemo(() => {
    return formValues.orderType === ORDER_TYPES.DELIVERY;
  }, [formValues.orderType]);

  const isDeliveryPickupEnable = useMemo(() => {
    return (
      formValues.orderType === ORDER_TYPES.DELIVERY ||
      formValues.orderType === ORDER_TYPES.PICKUP
    );
  }, [formValues.orderType]);

  useEffect(() => {
    async function processFormValues() {
      try {
        // console.log("formValues:::::: ", formValues);
        if (tableId) {
          // setInValid(false);
          const schemas = {
            paymentMethodForm: await SelectPaymentMethodSchema.isValid(
              formValues
            ),
          };
          const validationArray = Object.values(schemas);
          setInValid(validationArray.indexOf(false) > -1);
          setFormValidationGroups(schemas);
          return;
        }
        if (!moment(formValues?.deliveryTime).isAfter(moment())) {
          setInValid(true);
          return;
        }
        const schemas = {
          orderTypeForm: await SelectOrderTypeSchema.isValid(formValues),
          deliveryPickupTypeForm: isDeliveryPickupEnable
            ? await DeliveryTimeSchema.isValid(formValues)
            : true,

          contactForm: await ContactDetailsFormSchema.isValid(formValues),
          addressForm: isDeliveryEnable
            ? await AddressFormSchema.isValid(formValues)
            : true,
          paymentMethodForm: await SelectPaymentMethodSchema.isValid(
            formValues
          ),
        };
        const validationArray = Object.values(schemas);
        setInValid(validationArray.indexOf(false) > -1);
        setFormValidationGroups(schemas);
      } catch (error) {
        console.log("useCheckoutForm error", error);
      }
    }
    if (formValues) {
      processFormValues();
    }
  }, [formValues, isDeliveryEnable, isDeliveryPickupEnable]);

  const payment = async () => {
    // console.log("orderrrrrrrrrrr")

    if (inValid) {
      dispatch(
        showSnackbarAction("Please fill required information", "warning")
      );

      const invalidSection = Object.keys(formGroupsValidation).filter(
        (el) => formGroupsValidation[el] === false
      );

      if (invalidSection?.[0]) {
        document
          .getElementById(`checkout-${invalidSection[0]}`)
          .scrollIntoView({ behavior: "smooth", block: "center" });

        dispatch(addGroupRefIdWithMsg(invalidSection[0], null));
        setTimeout(() => dispatch(addGroupRefIdWithMsg(null, null)), 4000);
      }

      return false;
    }
    if (
      formValues.orderType === ORDER_TYPES.DELIVERY &&
      inValidDistance &&
      !tableId &&
      !location?.distance?.toString()
    ) {
      dispatch(showSnackbarAction(MESSAGES.INVALID_LOCATION, "warning"));

      document
        .getElementById(`checkout-addressForm`)
        .scrollIntoView({ behavior: "smooth", block: "center" });
      dispatch(addGroupRefIdWithMsg("addressForm", MESSAGES.INVALID_LOCATION));
      setTimeout(() => dispatch(addGroupRefIdWithMsg(null, null)), 4000);
      return false;
    }
    dispatch(order());
    return true;
  };

  return {
    orderButtonText,
    inValid,
    formGroupsValidation,
    formValues,
    setFormValues,
    payment,
  };
};

export default useCheckoutForm;
