import React from "react";
import clsx from "clsx";

// Material
import Typography from "@material-ui/core/Typography";
// Icons
import CheckIcon from "@material-ui/icons/Check";
// Styles
import useStyles from "./ChoiceItem.style.js";
import { CardMedia } from "@material-ui/core";
import * as noImage from "./../../assets/images/noImage.png";

const ChoiceItem = ({
  title,
  value,
  selected = false,
  handleClick = () => {},
  disabled,
  catImage,
  desc,
  isOrderType = false,
}) => {
  // console.log(":imageeeeeeee", catImage)
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        selected ? "selected" : null,
        disabled && classes.diabled,
        isOrderType && classes.orderTypeRoot
      )}
      onClick={() => handleClick(value)}
    >
      {!isOrderType && (
        <CardMedia
          className={clsx(classes.placeholderImg)}
          alt="Image from backend"
          component="img"
          src={catImage || noImage.default}
          onError={(e) => {
            e.target.src = noImage.default;
            e.target.className = `${e.target.className} no-image`;
          }}
        />
      )}
      <div
        className={
          !isOrderType
            ? classes.cardContentDiv
            : classes.orderTypeCardContentDiv
        }
      >
        <Typography className={classes.label} variant="caption">
          {title}
        </Typography>
        <Typography className={classes.descText} variant="body1">
          {desc}
        </Typography>
      </div>
      {/* <label className={classes.label}>{title}</label> */}
      {selected && <CheckIcon />}
    </div>
  );
};

export default ChoiceItem;
