// Utils
import Yup from "../../../utils/yup";

export default Yup.object().shape({
  customer_name: Yup.string().required("Required"),
  customer_phone: Yup.string()
    // .phone("NZ", true, "Phone number not valid!")
    .phone("Phone number not valid!")
    .required("Required"),
  customer_email: Yup.string().email("Invalid Email").required("Required"),
});
