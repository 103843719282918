import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "84px",
    // padding: theme.spacing(0, 1.6),
    background: theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "12px",
    border: `2px solid transparent`,
    cursor: "pointer",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  orderTypeRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: theme.palette.common.normalTitle,
  },
  diabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  placeholderImgActive: {
    width: "60px",
    marginRight: "10px",
    maxHeight: "60px",
    minHeight: "60px",
    borderTopLeftRadius: "10.9px",
  },
  placeholderImg: {
    width: "30%",
    marginRight: "10px",
    maxHeight: "100%",
    minHeight: "50px",
    borderTopLeftRadius: "10.9px",
    borderBottomLeftRadius: "10.9px",
  },
  cardContentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  orderTypeCardContentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
  },

  descText: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 300,
    color: theme.palette.common.normalTitle,
  },
}));

export default useStyles;
