// Utils
import {
  phoneNumberCountry,
  phoneNumberPrefix,
  numberPrefix,
} from "../../../config/app-config";
import Yup from "../../../utils/yup";

export default Yup.object().shape({
  customer_phone: Yup.string()
    .transform((values) => {
      // console.log("values in yup", values)
      if (values?.includes(phoneNumberCountry)) {
        return values;
      }

      // return `${phoneNumberPrefix}${values}`;
      return `${numberPrefix}${values}`;
    })
    .phone(phoneNumberCountry, true, "Phone number not valid!")
    .required()
    .test("Check prefix", function () {
      let customer_phone = this.parent["customer_phone"];
      if (customer_phone) {
        // return customer_phone.startsWith(phoneNumberPrefix) ? true : false;
        return customer_phone.startsWith(numberPrefix) ? true : false;
      }
    }),
});
