import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    userSelect: 'none',
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  itemText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 900,
    color: theme.palette.common.normalTitle,
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemIcon: {
    color: theme.palette.common.normalTitle,
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  modalTitleSection: {
    marginBottom: theme.spacing(1.6),
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
}));

export default useStyles;
