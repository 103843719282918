import * as moment from "moment";

export function getTodayTimeIntervals(from, to, timeGap, bufTime) {

  const startTime = convertToMoment(from).add(bufTime, "minutes");
  const endTime = convertToMoment(to);

  let time = convertToMoment(from)
    .add(bufTime, "minutes")
    .add(timeGap, "minutes");

  let intervals = [];
  if (startTime.isAfter(moment())) {
    intervals.push(startTime.format("hh:mm a"));
  }
  while (time.isBefore(endTime)) {
    // hiding this for now, revert this if there is any issue with slots
    // if (intervals.length === 0) {
    //   time = time.add(bufTime, "minutes");
    // }
    if (
      time.isAfter(moment().add(bufTime, "minutes")) &&
      time.isBetween(startTime, endTime)
    ) {
      intervals.push(time.format("hh:mm a"));
    }
    time = time.add(timeGap, "minutes");
  }
  if (endTime.isAfter(moment())) {
    intervals.push(endTime.format("hh:mm a"));
  }
  return intervals;
}

export function getTimeIntervals(from, to, timeGap, bufTime) {
  const startTime = convertToMoment(from).add(bufTime, "minutes");
  const endTime = convertToMoment(to);
  // console.log("start time", startTime)
  // console.log("end time", endTime)
  let time = convertToMoment(from).add(timeGap, "minutes");
  let intervals = [startTime.format("hh:mm a")];
  while (time.isBefore(endTime)) {
    if (time.isBetween(startTime, endTime)) {
      intervals.push(time.format("hh:mm a"));
    }
    time = time.add(timeGap, "minutes");
  }
  intervals.push(endTime.format("hh:mm a"));
  return intervals;
}

export function convertToMoment(time) {
  const dayStart = moment().startOf("D").format("YYYY-MM-DD");
  // return moment(dayStart + " " + time);
  const localDate = moment.utc(dayStart + " " + time).local();

  const newTime = localDate.format("HH:mm");

  return moment(dayStart + " " + newTime);
}
