import React, { useEffect, useState } from "react";
import { create } from "jss";
import { useDispatch, useSelector } from "react-redux";
// Material
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { StylesProvider, jssPreset } from "@material-ui/styles";
// Datepicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// Store
import { getTheme } from "./store/Theme/ThemeAction";
// Container
import App from "./containers/App";

import { CookiesProvider } from "react-cookie";

const defaultThemeConfig = {
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    values: {
      xs: 0,
      // sm: 710,
      sm: 830,
      md: 1024,
    },
  },
  overrides: {},
};

function ThemeApp() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const hotel = useSelector((state) => state.hotel);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [textColor, setTextColor] = useState("black");

  useEffect(() => {
    if (hotel) {
      // console.log("hotellll",hotel?.hotel)
      if (hotel?.hotel?.dark_theme_active === 1) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    }
  }, [hotel]);

  // console.log("theme",theme)

  useEffect(() => {
    // Convert theme color to RGB
    const rgbColor = hexToRgb(theme?.color?.primary);

    // Calculate color brightness
    const brightness = calculateBrightness(rgbColor);

    // Set text color based on brightness
    if (brightness > 128) {
      setTextColor("black"); // Use black text for light backgrounds
    } else {
      setTextColor("white"); // Use white text for dark backgrounds
    }
  }, [theme?.color?.primary]);

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  // Function to calculate color brightness
  const calculateBrightness = ({ r, g, b }) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // console.log("themeee", hotel?.hotel?.dark_theme_active)

  const jss = create({ plugins: [...jssPreset().plugins] });

  const configureTheme = ({ fonts, color }) => {
    return createMuiTheme({
      ...defaultThemeConfig,
      typography: {
        htmlFontSize: 16,
        fontFamily: fonts.poppins.fontFamily,
        poppins: fonts.poppins,
        rockwell: fonts.rockwell,
        ...fonts.fontWeights,
      },
      palette: {
        primary: {
          main:
            localStorage.getItem("themecolor") != null
              ? localStorage.getItem("themecolor")
              : color?.primary
              ? color?.primary
              : "#14213d",
          // main: "#a2d2ff",

          // main: "#9C0A35",
        },
        secondary: {
          main: color.secondary,
          // main: "#000000"
        },
        // background: color.background,

        common: {
          background: isDarkMode ? "#000000" : color.background,
          white: isDarkMode ? "#131719" : color.white,
          lighterBg: isDarkMode ? "#1E2427" : color.white,
          fontTitle: isDarkMode ? "#ffffff" : "#000",
          normalTitle: isDarkMode ? "#f1f1f1" : "#000000",
          black: isDarkMode ? "#000" : "#fff",
          subTitle: isDarkMode ? "gray" : "#000000",
          scrollBar: isDarkMode ? "#ababab" : "#f5f5f5",
          scrollBarIdle: isDarkMode ? "#666" : "#888",
          whiteTitle: "#FFFFFF",
          headerText: textColor,
          red: color.red,
          green: color.green,
          grey: "#414A50",
          lightGrey: "#c6c6c6",
        },
      },
    });
  };

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={configureTheme(theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
