import React, { useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./SelectOrderType.style.js";

const SelectOrderType = ({ value = null, onSelect = () => { } }) => {
  const classes = useStyles();
  const [selectedOderType, setOrderType] = useState(value);

  const { getOrderTypes } = useDynamicHotelData();

  const onOrderTypeSelect = (type) => {
    setOrderType(type);
    onSelect(type);
  };
  return (
    <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
      {getOrderTypes.map((o, i) => (
        <div
          key={i}
          className={clsx(
            classes.item,
            o.type === selectedOderType && "selected",
            // o.active === false && classes.itemDisabled
          )}
          onClick={() => onOrderTypeSelect(o.type)}
        >
          <Typography
            // color="primary"
            className={clsx(
              classes.itemText,
              o.type === selectedOderType && "selected"
            )}
            variant="caption"
          >
            {o.title}
          </Typography>
          <o.Icon
            className={clsx(
              classes.itemIcon,
              o.type === selectedOderType && "selected"
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default SelectOrderType;
