import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Icons
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Styles
import useStyles from "./HotelDetails.style";
// Controls
// import IconBtn from "./../Controls/IconButton/IconButton";

const HotelDetails = ({ desc = true, color = "white",darkTheme }) => {
  const classes = useStyles();
  const { hotel } = useSelector((state) => state.hotel);
  const hotelAddress = useMemo(() => {
    if (hotel) {
      const address = hotel.address[0];
      return `${address.streetName}, ${address.city}, ${address.country}, ${address.postalCode}`;
    }
    return "";
  }, [hotel]);
  // console.log("darkkkkkk",darkTheme)
  return (
    <div className={classes.container}>
      {
        darkTheme !== undefined ?
      <Typography
        className={clsx(classes.title)}
        style={{ color:darkTheme ? "white" : "black" }}
        variant="h1"
        // noWrap
        display="block"
      >
        {/* {APP_NAME} */}
        {hotel ? hotel.hotelName : ""}
      </Typography>
      :
      <Typography
      className={clsx(classes.title)}
      variant="h1"
      // noWrap
      display="block"
    >
      {/* {APP_NAME} */}
      {hotel ? hotel.hotelName : ""}
    </Typography>

      }
      {
       darkTheme !== undefined ?
      desc && (
        <Typography className={clsx(classes.location, color === "black" && classes.blackColor)}
        style={{  color:darkTheme ? "white" : "black" }}
          variant="h6">
          <LocationOnOutlinedIcon />
          {/* <IconBtn aria-label="search" component="span" icon={<LocationOnOutlinedIcon />} className={clsx(classes.iconBtn, classes.noPadingIcon, color === 'black' && classes.blackColor)} /> */}
          {hotelAddress ? `${hotelAddress}` : ""}
        </Typography>
      )
      :
      desc && (
        <Typography className={clsx(classes.location)}
          variant="h6">
          <LocationOnOutlinedIcon />
          {/* <IconBtn aria-label="search" component="span" icon={<LocationOnOutlinedIcon />} className={clsx(classes.iconBtn, classes.noPadingIcon, color === 'black' && classes.blackColor)} /> */}
          {hotelAddress ? `${hotelAddress}` : ""}
        </Typography>
      )
    }
    </div>
  );
};

export default HotelDetails;
