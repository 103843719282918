import { useMemo } from "react";
import { useSelector } from "react-redux";
import * as moment from "moment";
import useCheckoutForm from "./useCheckoutForm";
// Icons
import LocalDiningOutlinedIcon from "@material-ui/icons/LocalDiningOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
// Constants
import {
  ORDER_TYPES,
  PAYMENT_METHODS,
  DELIVERY_PICKUP_TYPES,
} from "../utils/Constants";
import {
  convertToMoment,
  getTimeIntervals,
  getTodayTimeIntervals,
} from "../utils/TimeUtils";
import { modifySlotsByType } from "../utils/utils";

function useDynamicHotelData() {
  const { hotel, tableId } = useSelector((state) => state.hotel);
  const { cart } = useSelector((state) => state);

  const { formValues } = useCheckoutForm();

  const modifySlotsWithType = (res) => {
    return (
      modifySlotsByType(
        hotel,
        cart,
        formValues,
        res,
        formValues?.deliveryTime
      ) || []
    );
  };

  const hotelStatus = useMemo(() => {
    if (hotel?.hotelStatus?.[0]) {
      return hotel.hotelStatus[0];
    }
    return {
      active: false,
      active_reason: "Hotel network is down",
    };
  }, [hotel]);

  const hotelOrderStatus = useMemo(() => {
    if (hotel) {
      return hotel.online_order_active === 1;
    }
    return {
      active: false,
    };
  }, [hotel]);

  const getOrderTypes = useMemo(() => {
    if (!hotel) {
      return [];
    }
    const orderTypes = [];

    if (hotel.delivery) {
      orderTypes.push({
        type: ORDER_TYPES.DELIVERY,
        title: "Delivery",
        active: hotel.deliveryAvailable,
        enabled: hotel.delivery,
        Icon: LocalShippingOutlinedIcon,
      });
    }
    if (hotel.pickup) {
      orderTypes.push({
        type: ORDER_TYPES.PICKUP,
        title: "Pickup",
        active: hotel.pickupAvailable,
        enabled: hotel.pickup,
        Icon: StoreOutlinedIcon,
      });
    }
    if (hotel.dine_in) {
      orderTypes.push({
        type: ORDER_TYPES.DINEIN,
        title: "Dine in",
        active: hotel.openingAvailable,
        Icon: LocalDiningOutlinedIcon,
      });
    }
    return orderTypes;
  }, [hotel]);

  const getPaymentMethods = useMemo(() => {
    if (!hotel) {
      return [];
    }
    const paymentTypes = [];
    // console.log("logggg", hotel?.payment_gateway)

    if (hotel.online_payment_enable) {
      hotel?.payment_gateway?.map((item) => {
        paymentTypes.push({
          type: PAYMENT_METHODS.ONLINE_PAYMENT,
          title: item?.gateway,
          label: item?.label,
          Icon: CreditCardOutlinedIcon,
          active: 1,
          id: item?.gateway_id,
        });
      });
    }
    if (tableId) {
      const tablePayments = hotel?.payment_table_side;
      tablePayments?.forEach((p) => {
        paymentTypes.push({
          ...p,
          title: p.type
            ? `${p.type[0].toUpperCase()}${p.type.substring(1)}`
            : "",
          Icon: LocalAtmOutlinedIcon,
        });
      });
    } else {
      const methods =
        formValues.orderType === ORDER_TYPES.PICKUP
          ? hotel.payment_pickup
          : hotel.payment_delivery;
      if (methods) {
        methods
          .filter((elem) => elem.active === 1)
          .forEach((p) => {
            paymentTypes.push({
              ...p,
              // title: p.type,
              title: p.type
                ? `${p.type[0].toUpperCase()}${p.type.substring(1)}`
                : "",
              Icon: LocalAtmOutlinedIcon,
            });
          });
      }
    }
    return paymentTypes;
  }, [hotel, formValues.orderType]);

  const getDeliveryPickupTypes = useMemo(() => {
    if (!hotel) {
      return [];
    }
    const data = [
      {
        type: DELIVERY_PICKUP_TYPES.ASAP,
        title: "ASAP",
        Icon: FlashOnOutlinedIcon,
      },
      {
        type: DELIVERY_PICKUP_TYPES.SCHEDULED,
        title: "Scheduled",
        Icon: AccessTimeOutlinedIcon,
      },
    ];

    return data;
  }, [hotel]);

  const isMultipleOrderType = useMemo(() => {
    if (getOrderTypes.filter((s) => s.enabled === 1).length > 1) {
      // if (getOrderTypes.filter((s) => console.log("ssssssssss", s))) {
      return true;
    }
    return false;
  }, [getOrderTypes]);

  const getAvailableDays = useMemo(() => {
    if (!hotel) {
      return [];
    }
    return hotel.workingInfo.map((elem) => elem.day);
  }, [hotel]);

  const isAsapAvailable = useMemo(() => {
    if (!hotel) {
      return false;
    }
    const bufTime =
      formValues.orderType === "DELIVERY"
        ? hotel.delivery_buffer_time
        : hotel.pickup_buffer_time;
    const time = moment().add(bufTime, "minutes");
    const dayInfo = hotel.workingInfo.find(
      (elem) => elem.day === moment().day()
    );
    if (dayInfo && dayInfo.hotelDayInfo) {
      const hours = dayInfo.hotelDayInfo.find(
        (elem) => elem.type === formValues.orderType
      );
      // console.log('time >>>>>>>>>>>>>>>>>>>>>',time.format("YYYY-MM-DD HH:mm"))

      const data = hours?.running_hours.map((elem) => {
        const start = convertToMoment(elem.start);
        const end = convertToMoment(elem.stop);

        // console.log(elem.start,' start >>>>>>>>>>>>>>>>>>>>>',start.format("YYYY-MM-DD HH:mm"))
        // console.log(elem.stop,' end >>>>>>>>>>>>>>>>>>>>>',end.format("YYYY-MM-DD HH:mm"))

        return time.isBetween(start, end);
      });
      // console.log('INSIDE>>>>>>>>>>>>>>>>>',data)
      return data ? data.reduce((prev, curr) => prev || curr) : false;
    } else {
      return false;
    }
  }, [hotel, formValues.orderType]);

  const getTodayDeliveryIntervalSlots = useMemo(() => {
    if (!hotel) {
      return [];
    }
    const bufTime =
      formValues.orderType === ORDER_TYPES.DELIVERY
        ? hotel.delivery_buffer_time
        : formValues.orderType === ORDER_TYPES.PICKUP
        ? hotel.pickup_buffer_time
        : 30;
    const dayInfo = hotel.workingInfo.find(
      (elem) => elem.day === moment().day()
    );
    if (dayInfo && dayInfo.hotelDayInfo) {
      let orderTypeValue = formValues.orderType;
      if (formValues.orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
        orderTypeValue = ORDER_TYPES.OPENING;
      }

      const hours = dayInfo.hotelDayInfo.find(
        (elem) => elem.type === orderTypeValue
      );
      const data = hours?.running_hours.map((elem) => {
        const options = getTodayTimeIntervals(
          elem.start,
          elem.stop,
          15,
          bufTime
        );
        return options;
      });

      const res = data ? data.reduce((prev, curr) => [...prev, ...curr]) : [];
      const result = modifySlotsWithType(res);
      return result;
    } else {
      return [];
    }
  }, [hotel, formValues.orderType, formValues.deliveryTime]);

  const getDeliveryIntervalSlots = useMemo(() => {
    if (!hotel) {
      return [];
    }
    const bufTime =
      formValues.orderType === ORDER_TYPES.DELIVERY
        ? hotel.delivery_buffer_time
        : formValues.orderType === ORDER_TYPES.PICKUP
        ? hotel.pickup_buffer_time
        : 30;
    const dayInfo = hotel.workingInfo.find(
      (elem) => elem.day === moment(formValues.deliveryTime).day()
    );
    if (dayInfo && dayInfo.hotelDayInfo) {
      let orderTypeValue = formValues.orderType;
      if (formValues.orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
        orderTypeValue = ORDER_TYPES.OPENING;
      }

      const hours = dayInfo.hotelDayInfo.find(
        (elem) => elem.type === orderTypeValue
      );
      const data = hours?.running_hours.map((elem) => {
        const options = getTimeIntervals(elem.start, elem.stop, 15, bufTime);
        return options;
      });
      const res = data ? data.reduce((prev, curr) => [...prev, ...curr]) : [];
      const result = modifySlotsWithType(res);
      return result;
    } else {
      return [];
    }
  }, [hotel, formValues.orderType, formValues.deliveryTime]);

  return {
    hotel,
    hotelStatus,
    hotelOrderStatus,
    getOrderTypes,
    getPaymentMethods,
    getDeliveryPickupTypes,
    isMultipleOrderType,
    getAvailableDays,
    isAsapAvailable,
    getTodayDeliveryIntervalSlots,
    getDeliveryIntervalSlots,
  };
}

export default useDynamicHotelData;
