import * as types from "./UserTypes";

//Replace action name and update action types
export const getUser = (payload) => ({
  type: types.GET_USER,
  payload,
});
export const createUser = (payload) => ({
  type: types.CREATE_USER_REQUEST,
  payload,
});
export const loginUser = (payload) => ({
  type: types.LOGIN_USER_REQUEST,
  payload,
});
export const logoutUser = () => ({
  type: types.LOGOUT_USER,
});
