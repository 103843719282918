import * as types from "./CheckoutTypes";

export const toggleCheckbox = () => ({
  type: types.UPDATE_CHECKBOX,
});

export const setError = (error) => ({
  type: types.UPDATE_ERROR,
  payload: error,
});

export const updateContactAccordian = (value) => ({
  type: types.UPDATE_CONTACT_ACCORDIAN,
  payload: value,
});
export const updateAddressAccordian = (value) => ({
  type: types.UPDATE_ADDRESS_ACCORDIAN,
  payload: value,
});
export const updatePaymentAccordian = (value) => ({
  type: types.UPDATE_PAYMENT_ACCORDIAN,
  payload: value,
});
