import * as types from "./ThemeTypes";

//Replace action name and update action types
export const getTheme = () => ({
  type: types.GET_THEME_REQUEST
});

export const getThemeReceive = payload => ({

  type: types.GET_THEME_RECEIVE,
  payload
});

