import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const createUser = async (userData) => {
  try {
    const { data } = await axiosInstance.post(
      `${API.ENDPOINTS.CREATE_USER}`,
      { ...userData },
      {
        handlerEnabled: false,
      }
    );
    return data?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};

export const loginUser = async (uid) => {
  try {
    const { data } = await axiosInstance.get(
      `${API.ENDPOINTS.LOGIN_USER}?uid=${uid}`,
      {
        handlerEnabled: false,
      }
    );
    return data?.data?.length ? data?.data?.[data?.data?.length - 1] : null;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};
