import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

// Material
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// Controls
import Btn from "../../Controls/Button/Button";
// Custom Hooks
import usePriceSummary from "../../../CustomHooks/usePriceSummary";
import useProduct from "../../../CustomHooks/useProduct";
// Actions
import { savePriceSummary } from "../../../store/Cart/CartActions";
// Styles
import useStyles from "./PriceSummary.style.js";
// Constants
import { CURRENCY, ORDER_TYPES } from "../../../utils/Constants";
import Modal from "../../Material/Modal/Modal";
import CouponSuccess from "../CouponSuccess/CouponSuccess";
import CouponList from "../CouponList/CouponList";
import useWalletPoints from "../../../CustomHooks/useWalletPoints.js";
import { setPriceWalletPoint } from "../../../store/Profile/ProfileActions.js";

const PriceSummary = ({ triggerCheckout }) => {
  const modalInit = {
    type: "",
    show: false,
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { voucherData } = useSelector((state) => state.voucher);
  const { hotelKey, hotel } = useSelector((state) => state.hotel);
  const { checkoutDetails, availableCoupons, selectedCoupon } = useSelector(
    (state) => state.cart
  );
  const locationState = useSelector((state) => state.location);
  const priceSummary = usePriceSummary();
  const subTotal = priceSummary.subTotal;
  const { getOriginalPrice } = useProduct();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modal, toggleModal] = useState(modalInit);
  const {
    checkIsProductLoyalityApplied,
    resetWalletProducts,
    getWalletPointsFromCartProducts,
  } = useWalletPoints();
  const { usedWalletPoints, priceWalletPoint } = useSelector(
    (state) => state.profile
  );

  const proceedHandler = () => {
    if (
      availableCoupons?.length > 0 &&
      Object.keys(selectedCoupon).length === 0 &&
      !checkIsProductLoyalityApplied()
    ) {
      toggleModal({
        type: "coupon",
        show: true,
      });
      return;
    }

    history.push(`/${hotelKey}/cart/checkout`);
  };

  const proceedToCheckout = () => {
    toggleModal({
      type: "",
      show: false,
    });
    history.push(`/${hotelKey}/cart/checkout`);
  };

  const selectCoupenHandler = (applied) => {
    toggleModal({
      ...modal,
      show: false,
    });
    if (applied) {
      toggleModal({
        type: "success",
        show: true,
      });
    }
  };

  const handleSkipButton = () => {
    toggleModal({
      ...modal,
      show: false,
    });
    history.push(`/${hotelKey}/cart/checkout`);
  };

  useEffect(() => {
    if (!locationState?.currentDeliveryZone) {
      return;
    }

    const { minimum_amount } = locationState.currentDeliveryZone;
    if (
      locationState?.isLocationProcessed &&
      Number(subTotal) > minimum_amount
    ) {
      setButtonDisabled(false);
    } else {
      if (checkoutDetails.orderType !== ORDER_TYPES.PICKUP) {
        setButtonDisabled(true);
      }
    }
  }, [locationState, subTotal]);

  useEffect(() => {
    if (triggerCheckout) {
      proceedHandler();
    }
  }, [triggerCheckout]);

  useEffect(() => {
    dispatch(
      savePriceSummary({
        total: priceSummary.total,
        totalWithoutDiscount: priceSummary.totalWithoutDiscount,
        subTotal: priceSummary.subTotal,
        subTotalWithoutDiscount: priceSummary.subTotalWithoutDiscount,
        discount: priceSummary.discount,
        tax: priceSummary.tax,
        serviceCharge: priceSummary.serviceCharge,
        deliveryCharge: priceSummary.deliveryCharge,
        walletAmount: priceSummary?.walletPoints,
      })
    );
    if (usedWalletPoints && priceSummary?.discount) {
      dispatch(setPriceWalletPoint(priceSummary?.discount));
    }
  }, [
    priceSummary.total,
    priceSummary.subTotal,
    priceSummary.discount,
    priceSummary.tax,
    priceSummary.serviceCharge,
    priceSummary?.walletPoints,
    priceSummary.deliveryCharge,
    priceSummary.subTotalWithoutDiscount,
    priceSummary.totalWithoutDiscount,
    dispatch,
  ]);

  useEffect(() => {
    resetWalletProducts(1);
  }, []);

  return (
    <div className={classes.root}>
      <Typography
        // color="primary"
        className={classes.headerTitle}
        variant="h2"
        noWrap
      >
        {"Price summary"}
      </Typography>
      <div className={classes.listContainer}>
        <div className={classes.listItem}>
          <Typography
            // color="primary"
            className={classes.itemText}
            variant="caption"
            noWrap
          >
            {"Sub-total"}
          </Typography>
          <div className={classes.grow} />
          <Typography
            // color="primary"
            className={classes.itemPrice}
            variant="caption"
            noWrap
          >{`${CURRENCY.SIGN}${getOriginalPrice(
            priceSummary.subTotal
          )}`}</Typography>
        </div>
        {checkoutDetails.orderType === ORDER_TYPES.DELIVERY && (
          <div className={classes.listItem}>
            <Typography
              // color="primary"
              className={classes.itemText}
              variant="caption"
              noWrap
            >
              {"Delivery charge"}
            </Typography>
            <div className={classes.grow} />
            <Typography
              // color="primary"
              className={classes.itemPrice}
              variant="caption"
              noWrap
            >{`${CURRENCY.SIGN}${priceSummary.deliveryCharge}`}</Typography>
          </div>
        )}
        <div className={classes.listItem}>
          <Typography
            // color="primary"
            className={classes.itemText}
            variant="caption"
            noWrap
          >
            {"Surcharge"}
          </Typography>
          <div className={classes.grow} />
          <Typography
            // color="primary"
            className={classes.itemPrice}
            variant="caption"
            noWrap
          >{`${CURRENCY.SIGN}${priceSummary.serviceCharge}`}</Typography>
        </div>
        {hotel?.hide_tax_label === 0 ? (
          <div className={classes.listItem}>
            <Typography
              // color="primary"
              className={classes.itemText}
              variant="caption"
              noWrap
            >
              {hotel?.tax_exclusive === 0
                ? "Tax (GST inclusive)"
                : "Tax (GST exclusive)"}
            </Typography>
            <div className={classes.grow} />
            <Typography
              // color="primary"
              className={classes.itemPrice}
              variant="caption"
              noWrap
            >{`${CURRENCY.SIGN}${priceSummary.tax}`}</Typography>
          </div>
        ) : (
          ""
        )}
        {priceSummary?.voucherDiscount != 0 && (
          <div className={classes.listItem}>
            <Typography
              // color="primary"
              className={classes.itemText}
              variant="caption"
              noWrap
            >
              {"Voucher Discount"}
            </Typography>
            <div className={classes.grow} />
            <Typography
              // color="primary"
              className={classes.itemPrice}
              variant="caption"
              noWrap
            >{`-${CURRENCY.SIGN}${priceSummary?.voucherDiscount}`}</Typography>
          </div>
        )}
        {/* {priceSummary?.walletPoints != 0 && (
          <div className={classes.listItem}>
            <Typography
              // color="primary"
              className={classes.itemText}
              variant="caption"
              noWrap
            >
              {"Wallet Discount"}
            </Typography>
            <div className={classes.grow} />
            <Typography
              // color="primary"
              className={classes.itemPrice}
              variant="caption"
              noWrap
            >{`-${CURRENCY.SIGN}${priceSummary?.walletPoints}`}</Typography>
          </div>
        )} */}
        {priceSummary?.remainingValue != 0 && (
          <div className={classes.listItem}>
            <Typography
              // color="primary"
              className={classes.itemText}
              variant="caption"
              noWrap
            >
              {"Balance Voucher"}
            </Typography>
            <div className={classes.grow} />
            <Typography
              // color="primary"
              className={classes.itemPrice}
              variant="caption"
              noWrap
            >{`${CURRENCY.SIGN}${priceSummary?.remainingValue}`}</Typography>
          </div>
        )}
        <div className={clsx(classes.listItem)}>
          <Typography
            // color="primary"
            className={clsx(classes.itemText, classes.greenText)}
            variant="caption"
            noWrap
          >
            {"Discount"}
          </Typography>
          <div className={classes.grow} />
          <Typography
            // color="primary"
            className={clsx(classes.itemPrice, classes.greenText)}
            variant="caption"
            noWrap
          >{`-${CURRENCY.SIGN}${priceSummary.discount}`}</Typography>
        </div>
        <Divider classes={{ root: classes.divider }} />
        <div className={clsx(classes.listItem, classes.total)}>
          <Typography
            // color="primary"
            className={classes.itemText}
            variant="caption"
            noWrap
          >
            {"Total"}
          </Typography>
          <div className={classes.grow} />
          <Typography
            // color="primary"
            className={clsx(classes.itemTotalPrice, classes.totalPrice)}
            variant="caption"
            noWrap
          >{`${CURRENCY.SIGN}${
            voucherData
              ? priceSummary.totalAfterAppliedVoucher
              : priceSummary.total
          }`}</Typography>
        </div>
      </div>
      {[`/${hotelKey}/cart`].indexOf(location.pathname) > -1 && (
        <div className={classes.mobileContainer}>
          {/* <Link to={`/${hotelKey}/cart/checkout`} className={classes.grow}> */}
          <Btn
            text={"Continue to checkout"}
            handleClick={proceedHandler}
            className={`${classes.btn}`}
            disabled={buttonDisabled}
          />
          {/* </Link> */}
        </div>
      )}
      <Modal
        customView={true}
        open={modal.show}
        handleClose={() =>
          toggleModal({
            type: "",
            show: false,
          })
        }
        modelContainer={
          modal.type === "success" && classes.orderTypeModalContainer
        }
      >
        {modal.type === "coupon" && (
          <CouponList
            handleClose={(d) => selectCoupenHandler(d)}
            coupons={availableCoupons || []}
            handleSkip={handleSkipButton}
          />
        )}
        {modal.type === "success" && (
          <CouponSuccess
            discount={priceSummary?.discount || 0}
            handleClose={() =>
              toggleModal({
                type: "",
                show: false,
              })
            }
            proceedToCheckout={proceedToCheckout}
            directCheckOut={true}
          />
        )}
      </Modal>
    </div>
  );
};

export default PriceSummary;
