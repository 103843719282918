import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #f5f5f5",
    background: "#000000",
    fontSize: "20px",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#f5f5f5",
    padding: "0",
  },
  avatarRoot: {
    display: "flex",
    alignItems: "center",
    height: "84px",
    // padding: theme.spacing(0, 1.6),
    background: theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "12px",
    border: `2px solid transparent`,
    cursor: "pointer",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  avatarIcon: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  drawer: {
    width: 295,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.background,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  filterDrawerContainer: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 4, 0, 4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 8),
    },
  },
  filterDrawerTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2.4),
  },
  card: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    marginRight: theme.spacing(2),
    overflow: "visible",
    display: "flex",
  },
  iconDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "20%",
  },
  textDiv: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 0,
    paddingTop: "25px",
  },
  text: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: theme.palette.common.normalTitle,
  },
}));

export default useStyles;
