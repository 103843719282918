import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 295,
        flexShrink: 0,
        [theme.breakpoints.up("sm")]: {
            width: 350,
        },
        [theme.breakpoints.up("md")]: {
            width: 592,
        },
    },
    drawerPaper: {
        backgroundColor: theme.palette.common.background,
        width: 295,
        [theme.breakpoints.up("sm")]: {
            width: 350,
        },
        [theme.breakpoints.up("md")]: {
            width: 592,
        },
    },
    filterDrawerContainer: {
        padding: theme.spacing(2.4, 1.6, 0, 1.6),
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2.4, 4, 0, 4),
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2.4, 12, 0, 8),
        },
    },
    filterDrawerTitle: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        marginBottom: theme.spacing(2.4),
    },
}))

export default useStyles;
