// Utils
import Yup from "../../../utils/yup";

export default Yup.object().shape({
  customer_name: Yup.string().required("Required"),
  // customer_phone: Yup.string().required("Required"),

  // customer_phone: Yup.string()
  //   .transform((values) => {
  //     if (values?.includes(phoneNumberCountry)) {
  //       return values;
  //     }

  //     // console.log("countryyyyyyyyyy", phoneNumberCountry)
  //     // console.log("prefixxxxxxxxx", numberPrefix)
  //     // return `${phoneNumberPrefix}${values}`;
  //     return `${numberPrefix}${values}`;

  //   })
  //   // .phone(phoneNumberCountry, false, "Phone number not valid!")
  //   .required()
  //   .test("Check prefix", "Phone number not valid!", function () {
  //     let customer_phone = this.parent["customer_phone"];
  //     if (customer_phone) {
  //       // return customer_phone.startsWith(phoneNumberPrefix) ? true : false;
  //       return customer_phone.startsWith(numberPrefix) ? true : false;

  //     }
  //   }),
  customer_phone: Yup.string()
    .matches(/^\d{8,}$/, "Phone number must contain at least 8 digits")
    .required("Phone number is required"),
  customer_email: Yup.string().email("Invalid Email").required("Required"),
});
