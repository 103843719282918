import React from "react";
import { Route } from "react-router-dom";

// Common InnerLayout
import InnerLayout from "../../Layouts/InnerLayout";
// Components
import Navbar from "./../../../components/Navbar/Navbar";
// Styles
import useStyles from "./CommonRoute.style.js";

const CommonRoute = ({ component: Component, mainHeader, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.appContianer}>
      <Navbar mainHeader={mainHeader} />
      <Route
        {...rest}
        render={(props) => (
          <InnerLayout>
            <Component {...props} />
          </InnerLayout>
        )}
      />
    </div>
  );
};

export default CommonRoute;
