import React from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { useSelector } from "react-redux";
// Routes
import history from "./History";

// Private Routes
import CommonRoute from "../utils/PrivateRoutes/CommonRoute/CommonRoute";
// Layouts
import CartLayout from "../utils/Layouts/CartLayout/CartLayout";
import MainLayout from "../utils/Layouts/MainLayout/MainLayout";
// Laze loading
import * as LazyComponent from "../utils/LazyLoaded";
import PrivateRoute from "../utils/PrivateRoutes/PrivateRoute/PrivateRoute";
import OrdersLayout from "../utils/Layouts/OrdersLayout/OrdersLayout";
import ProfileLayout from "../utils/Layouts/ProfileLayout/ProfileLayout";
import useCheckoutForm from "../CustomHooks/useCheckoutForm";

const OrdersRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <OrdersLayout>
            <LazyComponent.Orders {...props} />
          </OrdersLayout>
        )}
      />
    </Switch>
  );
};

const CartRoutes = () => {
  let { path } = useRouteMatch();
  const cart = useSelector((state) => state.cart);
  const { hotelKey, tableId } = useSelector((state) => state.hotel);
  const { formValues } = useCheckoutForm();
  const { nextAvailableTime } = useSelector((state) => state.time);

  // console.log("cart.data.length",cart.data.length)
  // console.log("formValues?.orderType",formValues?.orderType)
  // console.log("nextAvailableTime",nextAvailableTime)
  // console.log("tableId",tableId)
  if (tableId) {
    if (cart.data.length === 0 || tableId === null) {
      return <Redirect to={`/${hotelKey}`} />;
    }
  }
  if (!tableId) {
    if (
      cart.data.length === 0 ||
      formValues?.orderType === null ||
      nextAvailableTime === null
    ) {
      return <Redirect to={`/${hotelKey}`} />;
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <CartLayout>
            <LazyComponent.Cart {...props} />
          </CartLayout>
        )}
      />
      <Route
        exact
        path={`${path}/checkout`}
        render={(props) => (
          <CartLayout>
            <LazyComponent.Checkout {...props} />
          </CartLayout>
        )}
      />
    </Switch>
  );
};

const MainRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <MainLayout>
            <LazyComponent.Home {...props} />
          </MainLayout>
        )}
      />
    </Switch>
  );
};

const TableRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <LazyComponent.Table {...props} />}
      />
    </Switch>
  );
};

const ProfileRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <ProfileLayout>
            <LazyComponent.Profile {...props} />
          </ProfileLayout>
        )}
      />
      {/* <Route
        exact
        path={`${path}`}
        render={(props) => <LazyComponent.Profile {...props} />}
      /> */}
    </Switch>
  );
};

const AccountRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <ProfileLayout>
            <LazyComponent.Account {...props} />
          </ProfileLayout>
        )}
      ></Route>
    </Switch>
  );
};

const TakeyRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <LazyComponent.TakeyHome {...props} />}
      />
      <Route
        exact
        path={`${path}/restaurants`}
        render={(props) => <LazyComponent.TakeyRestaurants {...props} />}
      />
      <Route
        exact
        path={`${path}/restaurants/cusines`}
        render={(props) => <LazyComponent.TakeyRestaurantCuisines {...props} />}
      />
    </Switch>
  );
};

const HomeRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <LazyComponent.RootHome {...props} />}
      />
      <Route
        exact
        path={`${path}/restaurants`}
        render={(props) => <LazyComponent.HomeRestaurants {...props} />}
      />
    </Switch>
  );
};

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/home" component={HomeRoutes} />
        <Route
          exact
          path="/payment-callback"
          render={(props) => <LazyComponent.PaymentCallBack {...props} />}
        />
        <PrivateRoute component={TakeyRoutes} path="/takey" />
        <PrivateRoute
          component={TableRoutes}
          path="/:id/table/:tableId"
          exact
        />
        <CommonRoute component={MainRoutes} path="/:id" exact mainHeader />
        <CommonRoute component={CartRoutes} path="/:id/cart" />
        <CommonRoute component={OrdersRoutes} path="/:id/orders" />
        <CommonRoute component={ProfileRoutes} path="/:id/edit-profile" />
        <CommonRoute component={AccountRoutes} path="/:id/profile" />
        <Redirect from="**" to={`/home`} exact />
      </Switch>
    </Router>
  );
};

export default Routes;
