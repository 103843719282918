import * as Yup from "yup";
import "yup-phone";
import { numberPrefix } from "../config/app-config";
import { useSelector } from "react-redux";
import Auth from "./Auth";
// const mobilePhoneRegex =
//   /^(0|(\+64(\s|-)?)){1}(21|22|27){1}(\s|-)?\d{3}(\s|-)?\d{4}$/;

let mobilePhoneRegex;
if (numberPrefix === "+91") {
  mobilePhoneRegex = /^(0|91)?[6-9][0-9]{9}$/
} else if (numberPrefix === "+64") {
  mobilePhoneRegex = (/^(\+?64|0)(0)?/, '$1')
} else if (numberPrefix === "+61") {
  mobilePhoneRegex = /^(\+?61|0)4\d{8}$/
}

// Using custom test method
function isValidPhoneNumber(message) {
  return this.test("isValidPhoneNumber", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: message ?? "Empty mobile number" });
    }

    if (value.length < 9) {
      return createError({
        path,
        message: message ?? "Invalid mobile number",
      });
    }
    if (!value.match(mobilePhoneRegex)) {
      return createError({
        path,
        message: message ?? "Invalid mobile number",
      });
    }


    return true;
  });
}

Yup.addMethod(Yup.string, "validatePhoneNumber", isValidPhoneNumber);

export default Yup;
